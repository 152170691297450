.fulcrum-header {
  width: 95%;
  background: linear-gradient(180deg, #207589 0%, #4bacaf 100%);
  border-radius: 8px 0px 0px 0px;
  color: #fff;
  padding: 4px 16px;
}
.text-container {
  height: 100%;
  text-align: center;
  background: linear-gradient(0deg, #207589 -29.69%, #4bacaf 100%);
}
.text-container h6 {
  font-size: 10px;
}
